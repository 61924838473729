// #TODO: after addon release should be splitted to separate small components
import Vue from 'vue';
import 'whatwg-fetch';
import StripeProxy from 'shared/stripe_proxy';
import ShowErrors from 'shared/components/old_design/show_errors';
import VueResource from 'vue-resource';
import VueFloatLabel from "vue-float-label";
import lodash from 'lodash';
import VueLodash from 'vue-lodash';
import checkoutMixin from 'shared/checkout/mixins/common';
import * as PreventCache from 'shared/prevent_cache';
import { preselectPaymentMethod } from 'shared/checkout/preselect_payment_method';
import * as constants from 'shared/constants';
import { isPayLaterVisible } from 'shared/checkout/is_pay_later_visible';
import { isSequraVisible } from 'shared/checkout/is_sequra_visible';


Vue.use(VueLodash, lodash);

Vue.use(VueResource);
Vue.use(VueFloatLabel);

Vue.http.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

PreventCache.SubscribePageReloader();

document.addEventListener('DOMContentLoaded', () => {
  Vue.component('show-errors', ShowErrors);
  let rootElement = document.querySelector('#checkout-form');

  if(rootElement === null) { return; }

  let data = rootElement.dataset;

  let stripeProxy = new StripeProxy(
    $('meta[name="stripe-key"]').attr('content'),
    I18n.locale,
    data.stripeOrdersUrl,
    data.stripeConfirmationUrl,
    data.stripeSepaConfirmationUrl
  );

  toastr.options = {
    "newestOnTop": true,
    "progressBar": true,
    "positionClass": "toast-top-center",
    "toastClass": "toast theme-toast",
  };

  let product = JSON.parse(data.product);
  let paymentPlans = JSON.parse(data.paymentPlans);
  let addons = JSON.parse(data.addons);
  let orderFormErrorsData = JSON.parse(data.orderFormErrors) || {};
  let orderFormData = { ...JSON.parse(data.orderForm), ...JSON.parse(sessionStorage.getItem('buyerInfo')) };
  sessionStorage.removeItem('buyerInfo');
  let paymentErrorsData = JSON.parse(data.paymentErrors);
  let paymentMethods = JSON.parse(data.paymentMethods);
  let country = data.country;

  //prefill default data to prevent empty form validation
  if(!orderFormData.street) { orderFormData.street = ""; }
  if(!orderFormData.house_number) { orderFormData.house_number = ""; }
  if(!orderFormData.address) { orderFormData.address = ""; }
  if(!orderFormData.zipcode) { orderFormData.zipcode = ""; }
  if(!orderFormData.city) { orderFormData.city = ""; }
  if(!orderFormData.phone) { orderFormData.phone = ""; }
  if(!orderFormData.phone_code) { orderFormData.phone_code = ""; }
  if(!orderFormData.state) { orderFormData.state = ""; }
  if(!orderFormData.discount_percent) { orderFormData.discount_percent = ""; }
  if(!orderFormData.discount_amount) { orderFormData.discount_amount = ""; }
  if(!orderFormData.promocode_id) { orderFormData.promocode_id = ""; }
  if(!orderFormData.promocode) { orderFormData.promocode = ""; }

  new Vue({
    el: rootElement,
    mixins: [checkoutMixin],
    data: {
      stripe: stripeProxy.stripe,
      stripeProxy: stripeProxy,
      orderForm: orderFormData,
      firstStepCheckoutUrl: data.firstStepCheckoutUrl,
      pricingUrl: data.pricingUrl,
      saleabilityUrl: data.saleabilityUrl,
      findByCodeUrl: data.findByCodeUrl,
      firstPaymentPlanName: data.paymentPlanName,
      paymentMethod: orderFormData.payment_method,
      paymentMethods: paymentMethods,
      maxInvoicePaymentBruttoPrice: parseFloat(data.maxInvoicePaymentBruttoPrice),
      isStripeAvailable: paymentMethods.stripe,
      isPaypalAvailable: paymentMethods.paypal,
      isPaypalAvailableForFrontendProduct: paymentMethods.paypal,
      isSepaAvailable: paymentMethods.sepa,
      funnelIsAvailableForPaypal: product.funnel_is_available_for_paypal,
      bruttoPrice: orderFormData.brutto_price,
      initialNettoPrice: orderFormData.netto_price,
      nettoPrice: orderFormData.netto_price,
      vatAmount: orderFormData.vat_amount,
      shippingBruttoAmount: orderFormData.shipping_net_price,
      shippingNettoAmount: orderFormData.shipping_brutto_price,
      shippingVatAmount: orderFormData.shipping_vat_amount,
      vatPercent: orderFormData.vat_percent,
      productBruttoAmount: orderFormData.brutto_price,
      productNettoAmount: orderFormData.netto_price,
      productVatAmount: orderFormData.vat_amount,
      totalBruttoAmount: orderFormData.brutto_price,
      maxSepaBruttoPrice: parseFloat(data.maxSepaBruttoPrice),
      totalBruttoAmountPreview: !!product.is_trial ? orderFormData.default_trial_price : orderFormData.brutto_price,
      pickedCategoryOptions: orderFormData.picked_category_options,
      currencySign: data.currencySign,
      productSlug: product.slug,
      currentPaymentPlan: paymentPlans[0],
      orderFormErrors: orderFormErrorsData,
      commonErrorModalMessage: orderFormErrorsData.common_errors || "",
      paymentErrors: paymentErrorsData,
      showPaymentErrorsModal: !!paymentErrorsData,
      isAddressRequired: JSON.parse(data.isAddressRequired),
      isPhoneRequired: JSON.parse(data.isPhoneRequired),
      product: product,
      isProductTrial: !!product.is_trial,
      twoStepsCheckoutInfo: JSON.parse(data.twoStepsCheckoutInfo),
      bulletpoints: JSON.parse(data.productBulletpoints),
      productTemplate: JSON.parse(data.productTemplate),
      hidePaymentPlans: JSON.parse(data.hidePaymentPlans),
      paymentPlans: paymentPlans,
      addons: addons,
      normalAddons: addons.filter(addon => !addon.bump),
      bumpAddons: addons.filter(addon => addon.bump),
      isProductShippable: JSON.parse(data.isShippable),
      productUnavailable: JSON.parse(data.productUnavailable),
      countriesInfo: JSON.parse(data.countriesInfo),
      statesInfo: JSON.parse(data.statesInfo),
      inStockLeft: product.in_stock_left || null,
      isStockVisible: product.is_stock_visible || null,
      doBeforeGetPricingDataTimeout: null,
      country: country,
      checkVatEnabled: true,
      allowedForAll: JSON.parse(data.allowedForAll),
      onlyPrivatePersons: JSON.parse(data.onlyPrivatePersons),
      recentSalesInfo: JSON.parse(data.recentSales),
      recentSalePopupVisible: true,
      useHouseNumber: true,
      validatePaymentMethodUrl: data.validatePaymentMethodUrl,
      checkBlacklistEmailUrl: data.checkBlacklistEmailUrl,
      checkRecentProductChangesUrl: data.checkRecentProductChangesUrl,
      redirectToSecondStepUrl: data.redirectToSecondStepUrl,
      showForceReloadPopUp: false,
      checkoutOpenedTime: data.checkoutOpenedTime,
      sequraOrdersUrl: data.sequraOrdersUrl,
    },
    mounted: function() {
      preselectPaymentMethod(this)
    },
    methods: {
      addressParamsForPricingData: function() {
        return '{}';
      },
      doBeforeGetPricingData() {
        let vm = this;

        if(this.doBeforeGetPricingDataTimeout) {
          clearTimeout(this.doBeforeGetPricingDataTimeout);
        }

        Vue.set(vm, 'isRecalculating', true);
        this.doBeforeGetPricingDataTimeout = setTimeout(() => {
          vm.getPricingData();
        }, 700);
      },
    },
    computed: {
      maxCharge: function() {
        return Math.max(this.totalBruttoAmount, this.grossNextPayments());
      },
      isInvoiceAvailable: function () {
        let available = this.noRecurringPayments() && (isNaN(this.maxInvoicePaymentBruttoPrice) || this.maxCharge <= this.maxInvoicePaymentBruttoPrice);

        if (!available && this.isInvoiceChecked()) { this.paymentMethod = null; }

        return available;
      },
      isKlarnaAvailable: function () {
        let onlyRegularAddons = this.checkedAddons.filter(addon => !addon.trial_days).length === this.checkedAddons.length;
        let onlyEuroCountries = constants.EURO_COUNTRIES.includes(this.orderForm.country_code);
        let available = this.isOnlyOneTimePayments() && onlyRegularAddons && !this.isProductTrial &&
                        this.paymentMethods.klarna && onlyEuroCountries;

        if (!available && this.isKlarnaChecked()) { this.paymentMethod = null; }

        return available;
      },
      isPayPalAllowed: function() {
        return this.isPaypalAvailable;
      },
      isPayLaterAvailable: isPayLaterVisible,
      isSequraAvailable: isSequraVisible,
    },
  });
});
