import * as constants from 'shared/constants';

export function isSequraVisible() {
  let onlyRegularAddons = this.checkedAddons.filter(addon => !addon.trial_days).length === this.checkedAddons.length;
  let inAvailableCountries = constants.SEQURA_COUNTRY_CODES.includes(this.orderForm.country_code);
  let totalPriceInRange = this.totalBruttoAmount > 50 && this.totalBruttoAmount < 5000;
  let availableProductTypesAddons = this.checkedAddons.filter(
    addon => constants.SEQURA_PRODUCT_TYPES.includes(addon.source_product_type)
  ).length === this.checkedAddons.length;
  let available = inAvailableCountries && this.paymentMethods.sequra && this.isOnlyOneTimePayments() &&
                  onlyRegularAddons && totalPriceInRange && availableProductTypesAddons;

  if (!available && this.isSequraChecked()) { this.paymentMethod = null; }
  return available;
}
