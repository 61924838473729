import * as constants from 'shared/constants';

export function isPayLaterVisible() {
  let isPriceEligible = this.totalBruttoAmount >= 1 && this.totalBruttoAmount <= 5000;
  let available = this.isPayPalAllowed && this.isOnlyOneTimePayments() && this.onlyRegularAddons() &&
                  !this.isProductTrial && this.orderForm.country_code === constants.GERMANY_COUNTRY_CODE && isPriceEligible;
  if (!available && this.isPayLaterChecked()) { this.paymentMethod = null; }

  return available;
}
